import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import { Observable} from "rxjs";
import { reportApi } from '../../../@shared/constants/api-url.contant';
import { IReportRequest } from '../../../@shared/interfaces/dashboard.interface';

@Injectable({
  providedIn: 'root'
})
export class ReportService {
  headers = new HttpHeaders()

  constructor(private http: HttpClient) { }


  getReport(filters: IReportRequest): Observable<any> {
    return this.http.post(environment.url_api + reportApi, filters);
  }
}
