import {Injectable} from '@angular/core';
import {TransactionService} from "./transaction/transaction.service";
import {IAuthorByRoleRequest} from "../../@shared/interfaces/transaction.interface";

@Injectable({
  providedIn: 'root'
})
export class MidSelectionService {

  constructor(private transactionService: TransactionService) {
  }

  async getSuperMerchant(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.transactionService.getActorByRole({type: 'superMerchants'}).subscribe({
        next: res => {
          const sm = res.data as any[]
          resolve(sm.map(x => ({value: x._id, label: x.email})))
        },
        error: err => reject(new Error(err.error.message))
      })
    })
  }

  async getMerchant(smIds: string[]): Promise<any>{
    return new Promise((resolve, reject) => {
      this.transactionService.getActorByRole({type: 'merchants', ids: smIds}).subscribe({
        next: res => {
          const m = res.data as any[]
          let merchantsOption: any[] = mapData(m, mapIdMailOption);
          resolve(merchantsOption)
        },
        error: err => reject(new Error(err.error.message))
      })
    })
  }
  async gerOperator(smIds: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.transactionService.getActorByRole({type: 'operators', ids: smIds}).subscribe({
        next: res => {
          const o = res.data as any[]
          let operatorsOption: any[] = mapData(o, mapIdMailOption);
          resolve(operatorsOption)
        },
        error: err => reject(new Error(err.error.message))
      })
    })
  }

  getGroup(mIds?: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      let request: IAuthorByRoleRequest = {type: 'midGroups'}
      if (mIds) {
        request.ids = mIds
      }
      this.transactionService.getActorByRole(request).subscribe({
        next: res => {
          const g = res.data as any[]
          let groupsOption: any[] = mapData(g, mapGroupsOption);
          resolve(groupsOption)
        },
        error: err => reject(new Error(err?.error?.message))
      })
    })
  }

  getMid(gIds: string[]): Promise<any> {
    return new Promise((resolve, reject) => {
      this.transactionService.getActorByRole({type: 'mids', ids: gIds}).subscribe({
        next: res => {
          const m = res.data as any[]
          let midsOption: any[] = mapData(m, mapMidsOption);
          resolve(midsOption)
        },
        error: err => reject(new Error(err?.error?.message))
      })
    })
  }
}
const mapGroupsOption = (x: any) => ({ value: x.id, label: x.name });
const mapMidsOption = (x: any) => ({ value: x.id, label: `${x.name !== '' ? x.name : 'N.A.'} - ${x.code}` });
const mapIdMailOption = (x: any) => ({ value: x.id, label: x.email });
function mapData(data: any[], mapFn: (x: any) => { value: any, label: string }): any[] {
  return data.reduce((acc, item) => {
    return [...acc, ...item.data.map(mapFn)];
  }, []);
}