import {createFeatureSelector, createSelector} from "@ngrx/store";
import { DashboardState } from "./dashboard.reducer";

export const selectDashboardState = createFeatureSelector<DashboardState>('dashboard');

export const getFormFilters = createSelector(
    selectDashboardState, (state: DashboardState) => state.formFilters
);

export const getAllFilters = createSelector(
    selectDashboardState, (state: DashboardState) => state
);