import {Injectable} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {userGetAllApi, userGetByIdApi, userGetMerchantsByIdApi, userGetSuperMerchantInfos, userGetSuperMerchantsApi, userUpdateApi} from "../../../@shared/constants/api-url.contant";
import {IUserRequest, IUserUpdateRequest} from "../../interfaces/user.interface";

@Injectable({
  providedIn: 'root'
})
export class UserService {
  constructor(private http: HttpClient) {
  }

  getAll(req: IUserRequest): Observable<any> {
    return this.http.post(environment.url_api + userGetAllApi, req);
  }

	getById(id: string): Observable<any> {
		return this.http.get(environment.url_api + userGetByIdApi + id);
	}

	getMerchantsBySuperMerchantId(id: string): Observable<any> {
		return this.http.get(environment.url_api + userGetMerchantsByIdApi + id);
	}

  getSuperMerchantInfos(id: string): Observable<any> {
		return this.http.get(environment.url_api + userGetSuperMerchantInfos + id);
	}

  getSuperMerchants(): Observable<any> {
    return this.http.get(environment.url_api + userGetSuperMerchantsApi);
  }

  update(id: string, req: IUserUpdateRequest): Observable<any> {
    return this.http.patch(environment.url_api + userUpdateApi + id, req);
  }

  toggleActive(id: string, req: {toggle: boolean}): Observable<any> {
    return this.http.patch(environment.url_api + userUpdateApi + 'toggleActive/' + id, req);
  }
}
