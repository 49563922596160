{
  "name": "colossus-pay-dashboard",
  "version": "0.3.2",
  "scripts": {
    "--- LOCAL SERVER ---": "",
    "Local server localhost": "ng serve --configuration=development --live-reload true --hmr --port 5576 -o --ssl true --ssl-cert ./cert/cert.pem --ssl-key ./cert/key.pem",
    "Local server test": "ng serve --configuration=test --live-reload true --hmr --port 5576 -o --ssl true --ssl-cert ./cert/cert.pem --ssl-key ./cert/key.pem",
    "--- BUILD ---": "",
    "build dev": "ng build --configuration=development --base-href / && node prepare-deploy.js",
    "build staging": "ng build --configuration=staging --base-href / && node prepare-deploy.js",
    "build PROD": "ng build --configuration=production && node prepare-deploy.js",
    "-- VERSIONS --": "",
    "increment major": "npm --no-git-tag-version version major",
    "increment minor": "npm --no-git-tag-version version minor",
    "increment patch": "npm --no-git-tag-version version patch",
    "-- DEPLOY --": "",
    "deploy:dev": "gcloud run deploy dashboard-dev --source deploy --region europe-west3 --project tokyo-data-408512 --allow-unauthenticated",
    "deploy:test": "gcloud run deploy dashboard-test --source deploy --region europe-west3 --project tokyo-data-408512 --allow-unauthenticated",
    "deploy:prod": "gcloud run deploy dashboard --source deploy --region europe-west3 --project cpay-prod-408513 --allow-unauthenticated"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.0",
    "@angular/cdk": "^17.3.4",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/forms": "^17.1.0",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/router": "^17.1.0",
    "@auth0/auth0-angular": "^2.2.3",
    "@fortawesome/fontawesome-free": "^6.5.1",
    "@ngrx/effects": "^17.1.0",
    "@ngrx/store": "^17.1.0",
    "@ngrx/store-devtools": "^17.1.0",
    "@ngx-translate/core": "^15.0.0",
    "@ngx-translate/http-loader": "^8.0.0",
    "mdb-angular-input-mask": "file:mdb-angular-input-mask-6.0.0.tgz",
    "mdb-angular-ui-kit": "file:mdb-angular-ui-kit-6.0.0.tgz",
    "mdb-angular-treeview": "file:mdb-angular-treeview-6.0.0.tgz",
    "moment": "^2.30.1",
    "ngx-countup": "^13.1.0",
    "ngx-skeleton-loader": "^9.0.0",
    "rxjs": "~7.8.0",
    "tslib": "^2.3.0",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.1",
    "@angular/cli": "^17.1.1",
    "@angular/compiler-cli": "^17.1.0",
    "@types/jasmine": "~5.1.0",
    "fs-extra": "^11.2.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "typescript": "~5.3.2"
  }
}
