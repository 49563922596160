import {Directive, Input, OnDestroy, OnInit, TemplateRef, ViewContainerRef} from '@angular/core';
import {Subject, takeUntil} from "rxjs";
import {AuthUserService} from "../services/auth/auth-user.service";

@Directive({
  selector: '[notSelfProfile]'
})
export class NotSelfProfileDirective implements OnInit, OnDestroy {

  @Input() notSelfProfile: string = '';

  destroy$: Subject<void> = new Subject<void>();

  constructor(
    private viewContainerRef: ViewContainerRef,
    private templateRef: TemplateRef<any>,
    private auth: AuthUserService) {
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
      this.auth.isViewingOwnProfile(this.notSelfProfile).pipe(takeUntil(this.destroy$)).subscribe(bool => {
        // NOTA BENE RITORNA TRUE SE NON è LO STESSO PROFILO
        (!bool) ? this.viewContainerRef.createEmbeddedView(this.templateRef) : this.viewContainerRef.clear();
      })
  }
}
