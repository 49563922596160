import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {MdbModule} from "./modules/mdb.module";
import {SidenavComponent} from './components/sidenav/sidenav.component';
import {RouterLink, RouterLinkActive, RouterOutlet} from "@angular/router";
import {HttpClientModule} from "@angular/common/http";
import {LoadingBtnDirective} from "./directives/loading-btn.directive";
import {RoleDirective} from "./directives/role.directive";
import { NotSelfProfileDirective } from './directives/not-self-profile.directive';
import { DashboardFacade } from '../@shared/facade/dashboard.facade';

const components = [SidenavComponent]
const directives = [LoadingBtnDirective, RoleDirective, NotSelfProfileDirective]

@NgModule({
	declarations: [
		...components,
		...directives
	],
	imports: [
		CommonModule,
		MdbModule,
		RouterOutlet,
		HttpClientModule,
    RouterLink,
    RouterLinkActive,
	],
	exports: [
		MdbModule,
		...components,
		...directives
	],
	providers: [DashboardFacade]

})
export class CoreModule {
}
