import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {IApiBody, IApiResponse} from "../../interfaces/IApi";
import {filter, iif, Observable, of, switchMap, take} from "rxjs";
import {ILoginReq, ILoginRes, IUser, IUserRegistrationRequest, IVerifyMfaReq, IVerifyMfaRes} from "./auth-user.interface";
import {getUser} from "../../../store/auth/auth.selector";
import {select, Store} from "@ngrx/store";
import {ERole} from "../../../@shared/enum/role.enum";

@Injectable({
  providedIn: 'root'
})
export class AuthUserService {
  user$ = this.store.select(getUser)
  user: IUser;

  headers = new HttpHeaders()

  constructor(private http: HttpClient, private store: Store) {
    this.user$.subscribe(user => {
      this.user = user!;
    });
  }

  checkRole(roles: ERole[]): Observable<boolean> {
    return this.store.pipe(
      select(getUser),
      filter(user => user !== null),
      take(1),
      switchMap(user =>
        iif(
          () => !!user?.role,
          of(roles.includes(user?.role as ERole)),
          of(false)
        )
      )
    )
  }

  isViewingOwnProfile(email: string) {
    return this.store.pipe(
      select(getUser),
      filter(user => user !== null),
      take(1),
      switchMap(user => {
        return of(user?.email === email);
      })
    )
  }

  login(data: ILoginReq): IApiResponse<ILoginRes> {
    return this.http.post<IApiBody<ILoginRes>>(environment.url_api + '/auth/login', data, {headers: this.headers})
  }

  verifyMfa(data: IVerifyMfaReq): IApiResponse<IVerifyMfaRes> {
    return this.http.post<IApiBody<IVerifyMfaRes>>(environment.url_api + '/auth/login/verifyMfa', data, {headers: this.headers})
  }

  getMe(): Observable<IUser> {
    return this.http.get<IUser>(environment.url_api + '/auth/getMe', {headers: this.headers})
  }

  registrationSend(data: IUserRegistrationRequest): Observable<any> {
    return this.http.post(environment.url_api + '/auth/registration/send', data, {headers: this.headers})
  }

  registration(password: string, token: string): Observable<any> {
    return this.http.post(environment.url_api + `/auth/registration/${token}`, {password}, {headers: this.headers})
  }

  resetPasswordSend(email: string): Observable<any> {
    return this.http.patch(environment.url_api + '/auth/resetPassword/send', {email}, {headers: this.headers})
  }

  resetPassword(password: string, token: string): Observable<any> {
    return this.http.patch(environment.url_api + `/auth/resetPassword/${token}`, {password}, {headers: this.headers})
  }

  getToken(): string | null {
    return localStorage.getItem('token');
  }

  getRole(): ERole | null {
    return this.user?.role as ERole || null;
  }
}
