import {Directive, ElementRef, Input, OnChanges, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Directive({
  selector: '[loadingBtn]'
})
export class LoadingBtnDirective implements OnInit, OnChanges{
  @Input() loadingState: boolean = false;
  contentText: string = '';

  constructor(private elem: ElementRef, public translate: TranslateService) {}

  ngOnInit(): void {
    if (this.loadingState) {
      this.elem.nativeElement.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span><span class="visually-hidden">${this.translate.instant('loading')}</span>`;
      this.elem.nativeElement.disabled = true;
    }
  }

  ngOnChanges(changes: any): void {
    this.loadingState = changes.loadingState?.currentValue;
    if (this.loadingState) {
      this.contentText = this.elem.nativeElement.innerHTML;
      this.elem.nativeElement.innerHTML = `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true" ></span><span class="visually-hidden">${this.translate.instant('loading')}</span>`;
      this.elem.nativeElement.disabled = true;
    } else if (this.contentText) {
      this.elem.nativeElement.innerText = this.contentText;
      this.elem.nativeElement.disabled = false;
    }
  }
}
