import { Injectable } from '@angular/core';
import {HttpClient, HttpParams} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import {Observable} from "rxjs";
import {mid, midGetAllApi, midGetByIdApi, midNewBinanceMidApi, newMidApi} from "../../../@shared/constants/api-url.contant";
import {ICreateMid, IMidRequest, IUpdateMid} from "./mid.interface";
import { EAcquirer } from '../../../@shared/enum/acquirer.enum';

@Injectable({
  providedIn: 'root'
})
export class MidService {
  constructor(private http: HttpClient) {
  }

  getAll(req: IMidRequest): Observable<any> {
    return this.http.post(environment.url_api + midGetAllApi, req);
  }

  getMidById(id: string): Observable<any> {
    return this.http.get(environment.url_api + midGetByIdApi + id);
  }

  getNewBinanceMid(): Observable<any> {
    return this.http.get(environment.url_api + midNewBinanceMidApi);
  }

  getNewMid(acquirer: EAcquirer): Observable<any> {
    const params = new HttpParams().set('provider', acquirer);
    return this.http.get(environment.url_api + `${newMidApi}`, {params});
  }

  create(req: ICreateMid): Observable<any> {
    return this.http.post(environment.url_api + mid, req);
  }

  update(id: string, req: IUpdateMid): Observable<any> {
    return this.http.patch(environment.url_api + `${mid}/${id}`, req);
  }

  generateToken(id: string): Observable<any> {
    return this.http.get(environment.url_api + `${mid}/generateToken/${id}`);
  }

  toggleActive(id: string, req: any): Observable<any> {
    return this.http.patch(environment.url_api + `${mid}/toggleActive/${id}`, req);
  }

  toggleDisabled(id: string, req: any): Observable<any> {
    return this.http.patch(environment.url_api + `${mid}/toggleDisabled/${id}`, req);
  }
  
  getByMerchantId(id: string): Observable<any> {
	return this.http.get(environment.url_api + `${mid}/getByMerchantId/${id}`);
  }

  midUpdate(id: string, accepted: boolean): Observable<any> {
    return this.http.patch(environment.url_api + `${mid}/updateRequest/${id}`, {toggle: accepted});
  }
}
