export const reportApi = '/report';

export const transactionApi = '/transaction';

export const headersApi = '/transaction/headersCsv'
export const excelDownloadApi = '/transaction/csv'
export const userGetAllApi = '/userManager';
export const userGetByIdApi = '/userManager/';
export const userUpdateApi = '/userManager/';
export const userGetMerchantsByIdApi = '/userManager/getMerchantsBySuperMerchantId/';
export const userGetSuperMerchantsApi = '/userManager/getSuperMerchants';
export const userGetSuperMerchantInfos = '/userManager/getSuperMerchantInfos/';

export const mid = '/mid';
export const midGetAllApi = `${mid}/getAll`;
export const midGetByIdApi = `${mid}/`;
export const midNewBinanceMidApi = `${mid}/newBinanceMid`;
export const newMidApi = `${mid}/newMid`;

export const group = '/midGroup';
export const groupGetAllApi = `${group}/getAll`;

export const apiKey = '/api-key';
export const apiKeyGetByMidIdApi = `${apiKey}/getByMidId`;

export const sendRefundApi = '/refund';
