import {NgModule} from '@angular/core';
import {CommonModule} from "@angular/common";
import {MdbRippleModule} from "mdb-angular-ui-kit/ripple";
import {MdbSidenavModule} from "mdb-angular-ui-kit/sidenav";
import {MdbCollapseModule} from "mdb-angular-ui-kit/collapse";
import {MdbFormsModule} from "mdb-angular-ui-kit/forms";
import {MdbNotificationModule} from "mdb-angular-ui-kit/notification";
import {MdbModalModule} from "mdb-angular-ui-kit/modal";
import {MdbDropdownModule} from "mdb-angular-ui-kit/dropdown";
import {MdbCheckboxModule} from "mdb-angular-ui-kit/checkbox";
import {MdbTableModule} from "mdb-angular-ui-kit/table";
import {MdbScrollbarModule} from "mdb-angular-ui-kit/scrollbar";
import {MdbInputMaskModule} from "mdb-angular-input-mask";
import {MdbSelectModule} from "mdb-angular-ui-kit/select";
import {MdbTabsModule} from "mdb-angular-ui-kit/tabs";
import {MdbTreeviewModule} from "mdb-angular-treeview";
import { MdbValidationModule } from 'mdb-angular-ui-kit/validation';

const mdbModules = [
	MdbRippleModule,
	MdbSidenavModule,
	MdbCollapseModule,
	MdbFormsModule,
	MdbNotificationModule,
  MdbModalModule,
  MdbDropdownModule,
  MdbTableModule,
  MdbScrollbarModule,
  MdbCheckboxModule,
  MdbInputMaskModule,
  MdbSelectModule,
  MdbTabsModule,
  MdbTreeviewModule,
  MdbValidationModule
]

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		...mdbModules
	],
	exports: [
		...mdbModules
	]
})
export class MdbModule {
}
