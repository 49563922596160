import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import { sendRefundApi} from "../../../@shared/constants/api-url.contant";
import {IRefund} from "./refund.interface";

@Injectable({
  providedIn: 'root'
})
export class RefundService {
  headers = new HttpHeaders()

  constructor(private http: HttpClient) { }


  sendRefund(req: IRefund): Observable<any> {
    return this.http.post(environment.url_api + sendRefundApi, req);
  }
}
