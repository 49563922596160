<mdb-sidenav-layout>
  <mdb-sidenav id="sidenav1" [mode]="'side'" [accordion]="false" [hidden]="false">
    <div class="side d-flex flex-column" style="height: 100vh;">
      <div class="text-center">
        <img alt="img" src="assets/logo_colorato.svg" class="img-fluid py-4" width="180">
        <hr class="m-0"/>
      </div>
      <ul class="sidenav-menu mb-auto">
        <mdb-sidenav-item>
          <a (click)="resetFilters()" class="sidenav-link" tabindex="0" mdbRipple routerLink="home" routerLinkActive="active">
            <i class="fas fa-home fa-fw me-3"></i>
            <span>Dashboard</span>
          </a>
        </mdb-sidenav-item>
        <hr class="m-0">
        <mdb-sidenav-item>
          <a (click)="resetFilters()" class="sidenav-link" tabindex="0" mdbRipple routerLink="transaction" routerLinkActive="active">
            <i class="far fa-credit-card fa-fw me-3"></i>
            <span>Transaction</span>
          </a>
        </mdb-sidenav-item>
        <mdb-sidenav-item>
          <a (click)="resetFilters()" class="sidenav-link" tabindex="0" mdbRipple routerLink="refunds" routerLinkActive="active">
            <i class="fas fa-undo fa-fw me-3"></i>
            <span>Refunds</span>
          </a>
        </mdb-sidenav-item>
        <mdb-sidenav-item *roles="[roleEnum.ADMIN, roleEnum.SUPER_MERCHANT]">
          <a (click)="resetFilters()" class="sidenav-link" tabindex="0" mdbRipple routerLink="users" routerLinkActive="active">
            <i class="fas fa-user fa-fw me-3"></i>
            <span>Users</span>
          </a>
        </mdb-sidenav-item>
        <mdb-sidenav-item *roles="[roleEnum.ADMIN, roleEnum.SUPER_MERCHANT, roleEnum.MERCHANT]">
          <a (click)="resetFilters()" class="sidenav-link" tabindex="0" mdbRipple routerLink="mid-management" routerLinkActive="active">
            <i class="fas fa-cog fa-fw me-3"></i>
            <span>MID Management</span>
          </a>
        </mdb-sidenav-item>
      </ul>
      <div class="text-center mb-2">
        <hr class="mt-0 mb-2"/>
        <small>version <i>{{ version }}</i></small>
      </div>
    </div>

  </mdb-sidenav>

  <mdb-sidenav-content>
    <!-- Navbar -->
    <nav class="navbar navbar-expand-lg navbar-light bg-light p-0">
      <div class="container-fluid justify-content-between">
        <div class="d-flex">
          <span><b>Welcome back: </b><em>{{ (user$ | async)?.email }}</em></span>
        </div>
        <ul class="navbar-nav flex-row">
          <li mdbDropdown class="nav-item dropdown">
            <button type="button" class="btn btn-primary btn-floating btn-sm my-2" mdbRipple mdbDropdownToggle>
              <i class="fas fa-user"></i>
            </button>
            <ul
              mdbDropdownMenu
              class="dropdown-menu dropdown-menu-end"
              aria-labelledby="navbarDropdownMenuLink"
            >
              <li class="mouse-pointer"><a class="dropdown-item" (click)="goToMyProfile()">My profile</a></li>
              <li class="mouse-pointer"><a class="dropdown-item" (click)="logout()">Logout</a></li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
    <!-- Navbar -->

    <div class="container mt-3">
      <ng-content></ng-content>
    </div>
    <!-- Toggler -->
  </mdb-sidenav-content>
</mdb-sidenav-layout>
