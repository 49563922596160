import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {environment} from "../../../../environments/environment";
import { Observable} from "rxjs";
import { excelDownloadApi, headersApi, transactionApi } from '../../../@shared/constants/api-url.contant';
import {IAuthorByRoleRequest, IExcelDownloadRequest, IGetExcelHeadersRequest, ITransactionRequest} from '../../../@shared/interfaces/transaction.interface';

@Injectable({
  providedIn: 'root'
})
export class TransactionService {
  constructor(private http: HttpClient) { }


  getTransactions(req: ITransactionRequest): Observable<any> {
    return this.http.post(environment.url_api + transactionApi, req);
  }

  getTransactionById(id: string): Observable<any> {
    return this.http.get(`${environment.url_api}${transactionApi}/${id}`);
  }

  getActors(): Observable<any> {
    return this.http.get(`${environment.url_api}${transactionApi}/actors`);
  }

  getActorByRole(req: IAuthorByRoleRequest): Observable<any> {
    return this.http.post(`${environment.url_api}${transactionApi}/actorByRole`, req);
  }

  getExcelHeaders(req: IGetExcelHeadersRequest): Observable<any> {
    return this.http.post(`${environment.url_api}${headersApi}`, req);
  }

  excelDownload(request: IExcelDownloadRequest): Observable<any> {
    const headers = new HttpHeaders();
    return this.http.post(`${environment.url_api}${excelDownloadApi}`, request, {headers, responseType: 'blob' as 'json'});
  }
}
