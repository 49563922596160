import { createActionGroup, emptyProps, props } from "@ngrx/store";
import { IPagination } from "../../@shared/interfaces/dashboard.interface";
import { FilterButtonValue } from "../../@shared/enum/filter-button.enum";
import { IDateRangeUnix } from "../../@shared/interfaces/filter-button-group.interface";
import { TabType } from "../../@shared/enum/tab-type.enum";

export const filtersChoicesActions = createActionGroup({
    source: 'filtersChoices',
    events: {
        'set filters choices': props<{ formFilters: any }>(),
        'get filters choices': props<{ formFilters: any }>(),
        'reset filters':emptyProps(),
        'reset pagination':emptyProps(),
        'set docId': props<{ docId: string | undefined }>(),
        'set dateRange': props<{ dateRange: IDateRangeUnix }>(),
        'set activeRadio': props<{ activeRadio: FilterButtonValue, dateRange: IDateRangeUnix | undefined }>(),
        'set pagination': props<{ pagination: IPagination }>(),
        'set tabType': props<{ tabType: TabType }>(),
    }
});

export const dashboardActions = {
    ...filtersChoicesActions,
}