import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {apiKey, apiKeyGetByMidIdApi} from "../../../@shared/constants/api-url.contant";
import {ICreateApiKey} from "./api-key.interface";

@Injectable({
  providedIn: 'root'
})
export class ApiKeyService {
	constructor(private http: HttpClient) {
	}

	create(req: ICreateApiKey): Observable<any> {
		return this.http.post(environment.url_api + apiKey, req);
	}

	getApiKeyByMid(mid: string): Observable<any> {
		return this.http.get(environment.url_api + apiKeyGetByMidIdApi + `/${mid}`);
	}

	removeApiKey(id: string): Observable<any> {
		return this.http.delete(environment.url_api + apiKey + `/${id}`);
	}
}
