import { Injectable } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {environment} from "../../../../environments/environment";
import {group, groupGetAllApi} from "../../../@shared/constants/api-url.contant";
import {IGroupCreationRequest, IGroupRequest, IGroupUpdateRequest} from "./group.interface";

@Injectable({
  providedIn: 'root'
})
export class GroupService {
	constructor(private http: HttpClient) {
	}

	getAll(req: IGroupRequest): Observable<any> {
		return this.http.post(environment.url_api + groupGetAllApi, req);
	}

	getGroupById(id: string): Observable<any> {
		return this.http.get(environment.url_api + group + '/' + id);
	}

	getGroupByMerchantId(id: string): Observable<any> {
		return this.http.get(environment.url_api + group + '/getByMerchantId/' + id);
	}

	createGroup(req: IGroupCreationRequest): Observable<any> {
		return this.http.post(environment.url_api + group, req);
	}

	updateGroup(req: IGroupUpdateRequest): Observable<any> {
		return this.http.patch(environment.url_api + group + '/' + req._id, req);
	}
}
