import {createFeatureSelector, createSelector} from "@ngrx/store";
import {AuthState} from "./auth.reducer";

export const selectAuthState = createFeatureSelector<AuthState>('auth');

export const getUser = createSelector(
  selectAuthState, (state: AuthState) => state.user
);

export const getUserError = createSelector(
  selectAuthState, (state: AuthState) => state.error
);
