import {Injectable} from "@angular/core";
import {Store} from "@ngrx/store";
import {dashboardActions} from "../../store/dashboard/dashboard.actions";
import {getAllFilters, getFormFilters} from "../../store/dashboard/dashboard.selector";
import {ReportService} from "../../@core/services/report/report.service";
import {TransactionService} from "../../@core/services/transaction/transaction.service";
import {ITransactionRequest} from "../interfaces/transaction.interface";
import {getUser} from "../../store/auth/auth.selector";
import {IPagination, IReportRequest} from "../interfaces/dashboard.interface";
import {UserService} from "../../@core/services/user/user.service";
import {IUserRegistrationRequest} from "../../@core/services/auth/auth-user.interface";
import {IUserRequest, IUserUpdateRequest} from "../../@core/interfaces/user.interface";
import {AuthUserService} from "../../@core/services/auth/auth-user.service";
import {MidService} from "../../@core/services/mid/mid.service";
import {ICreateMid, IMidRequest, IUpdateMid} from "../../@core/services/mid/mid.interface";
import {IRefund} from "../../@core/services/refund/refund.interface";
import {RefundService} from "../../@core/services/refund/refund.service";
import {map} from "rxjs";
import {GroupService} from "../../@core/services/group/group.service";
import {IGroupCreationRequest, IGroupRequest, IGroupUpdateRequest} from "../../@core/services/group/group.interface";
import {ApiKeyService} from "../../@core/services/api-key/api-key.service";
import {ICreateApiKey} from "../../@core/services/api-key/api-key.interface";
import {MidSelectionService} from "../../@core/services/mid-selection.service";
import {ERole} from "../enum/role.enum";
import { FilterButtonValue } from "../enum/filter-button.enum";
import { IDateRangeUnix } from "../interfaces/filter-button-group.interface";
import { TabType } from "../enum/tab-type.enum";
import { EAcquirer } from "../enum/acquirer.enum";

@Injectable()
export class DashboardFacade {
	filters$ = this.store.select(getFormFilters);
	allFilters$ = this.store.select(getAllFilters);
	user$ = this.store.select(getUser);

	constructor(
		private store: Store,
		private reportService: ReportService,
		private transactionService: TransactionService,
		private userService: UserService,
		private authService: AuthUserService,
		private midService: MidService,
		private apiKeyService: ApiKeyService,
		private groupService: GroupService,
    private midSelectionService: MidSelectionService,
		private refundService: RefundService) {
	}


	setFilterChoices(choices: any) {
		this.store.dispatch(dashboardActions.setFiltersChoices({formFilters: choices}));
	}

	setTabType(tabType: TabType) {
		this.store.dispatch(dashboardActions.setTabType({tabType: tabType}));
	}

	setPagination(pagination: IPagination) {
		this.store.dispatch(dashboardActions.setPagination({pagination: pagination}));
	}

	setDocId(docId: string | undefined) {
		this.store.dispatch(dashboardActions.setDocId({docId: docId}));
	}

	setActiveRadio(activeRadio: FilterButtonValue, dateRange: IDateRangeUnix | undefined) {
		this.store.dispatch(dashboardActions.setActiveRadio({activeRadio: activeRadio, dateRange: dateRange}));
	}

	setDateRange(dateRange: IDateRangeUnix) {
		this.store.dispatch(dashboardActions.setDateRange({dateRange: dateRange}));
	}

	resetFilters() {
		this.store.dispatch(dashboardActions.resetFilters());
	}

	resetPagination() {
		this.store.dispatch(dashboardActions.resetPagination());
	}

	getReport(filters: IReportRequest) {
		return this.reportService.getReport(filters);
	}

	getTransactions(req: ITransactionRequest) {
		return this.transactionService.getTransactions(req);
	}

	getTransactionById(id: string) {
		return this.transactionService.getTransactionById(id);
	}

	getUsers(req: IUserRequest) {
		return this.userService.getAll(req)
	}

	getUserById(id: string) {
		return this.userService.getById(id);
	}

	getMerchantsBySuperMerchantId(id: string) {
		return this.userService.getMerchantsBySuperMerchantId(id).pipe(map(
			(res: any) => res.response.map(
				(merchant: any) => ({label: merchant.email, value: merchant._id})
			)
		));
	}

  getSuperMerchantInfos(id: string) {
    return this.userService.getSuperMerchantInfos(id);
  }

	getSuperMerchants() {
		return this.userService.getSuperMerchants().pipe(map(
			(res: any) => res.response.map(
				(merchant: any) => ({label: merchant.email, value: merchant._id, extraValue: {userMerchantsId: merchant['userMerchantsId']}})
			)
		));
	}

	createUser(req: IUserRegistrationRequest) {
		return this.authService.registrationSend(req)
	}

	updateUser(id: string, req: IUserUpdateRequest) {
		return this.userService.update(id, req)
	}

	userToggleActive(id: string, req: any) {
		return this.userService.toggleActive(id, req)
	}


	/** MID **/
	getMids(req: IMidRequest) {
		return this.midService.getAll(req);
	}

	getMidById(id: string) {
		return this.midService.getMidById(id);
	}

	getNewBinanceMid() {
		return this.midService.getNewBinanceMid();
	}

	getNewMid(acquirer:EAcquirer) {
		return this.midService.getNewMid(acquirer);
	}

	createMid(req: ICreateMid) {
		return this.midService.create(req);
	}

	updateMid(id: string, req: IUpdateMid) {
		return this.midService.update(id, req);
	}

	getMidsByMerchantId(id: string) {
		return this.midService.getByMerchantId(id);
	}

	sendRefund(req: IRefund) {
		return this.refundService.sendRefund(req);
	}

	generateToken(id: string) {
		return this.midService.generateToken(id);
	}

	midToggleActive(id: string, req: any) {
		return this.midService.toggleActive(id, req);
	}

	midToggleDisabled(id: string, req: any) {
		return this.midService.toggleDisabled(id, req);
	}

	midUpdateRequest(id: string, accepted: boolean) {
		return this.midService.midUpdate(id, accepted);
	}

	/** GROUP **/
	getGroups(req: IGroupRequest) {
		return this.groupService.getAll(req);
	}
	getGroupById(id: string) {
		return this.groupService.getGroupById(id);
	}

	getGroupsByMerchantId(id: string) {
		return this.groupService.getGroupByMerchantId(id);
	}

	createGroup(req: IGroupCreationRequest) {
		return this.groupService.createGroup(req);
	}

	updateGroup(req: IGroupUpdateRequest) {
		return this.groupService.updateGroup(req);
	}

	/** API KEY **/
	createApiKey(req: ICreateApiKey) {
		return this.apiKeyService.create(req);
	}

	getApiKeyByMid(mid: string) {
		return this.apiKeyService.getApiKeyByMid(mid);
	}

	removeApiKey(id: string) {
		return this.apiKeyService.removeApiKey(id);
	}

  async loadAllMidSelection(): Promise<{superMerchant: string[], merchant: string[], group: string[], mid: string[]} | any>{
    try {
      const role: ERole = this.authService.getRole()!
      let midFilter: {superMerchant: string[], merchant: string[], group: string[], mid: string[]} = {superMerchant: [], merchant: [], group: [], mid: []}
      const SM: {value: string, label: string}[] = await this.midSelectionService.getSuperMerchant()
      if(SM?.length > 0) {
        midFilter.superMerchant = SM.map(sm => sm.value)
      } else return

      if(role === ERole.OPERATOR){
        midFilter.merchant = [this.authService.user._id]
      } else {
        const M: {value: string, label: string}[] = await this.midSelectionService.getMerchant(midFilter.superMerchant)
        if(M?.length > 0) {
          midFilter.merchant = M.map(m => m.value)
        } else return
      }

      let G: {value: string, label: string}[] = []
      if(role === ERole.OPERATOR){
        G = await this.midSelectionService.getGroup()
      } else {
        G = await this.midSelectionService.getGroup(midFilter.merchant)
      }
      if(G?.length > 0) {
        midFilter.group = G.map(g => g.value)
      } else return

      const MID: {value: string, label: string}[] = await this.midSelectionService.getMid(midFilter.group)
      if(MID?.length > 0) {
        midFilter.mid = MID.map(mid => mid.value)
      } else return
      return midFilter
    } catch (e) {
      return e
    }
  }

}
